/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3a4e945d-7ad0-4d3b-a275-b348548e245e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_4EhrLsXhI",
    "aws_user_pools_web_client_id": "48kgl7otbllk6jvvq7uucl9471",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://aajxsyuaszf37ou3pg47ri55o4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ahiwumawdbhxrlrcpehxv3ftlu",
    "aws_cloud_logic_custom": [
        {
            "name": "droneSendCoords",
            "endpoint": "https://u5wc9yfu20.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
